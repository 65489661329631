import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearCartData, getCartItemsStart } from "redux/cart";
import {
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  getOrdersStart,
  getOrdersBySellerIdStart,
  getOrderByOrderIdStart,
  getOrderSuccess,
  getOrdersBySellerIdSuccess,
  updateOrderStatusStart,
  updateOrderStatusSuccess,
} from "redux/order";
import {
  createOrder,
  getOrders,
  getOrdersBySellerId,
  getOrderByOrderId,
  updateOrderStatus,
} from "redux/services/order";
import { getAccessToken } from "utils/auth";

function* OrderSagaWatcher() {
  yield takeLatest(
    [
      createOrderStart.type,
      getOrdersStart.type,
      getOrdersBySellerIdStart.type,
      getOrderByOrderIdStart.type,
      updateOrderStatusStart.type,
    ],
    orderWorker
  );
}

function* orderWorker(action: any): any {
  try {
    if (getAccessToken()) {
      switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case createOrderStart.type:
          {
            if (getAccessToken()) {
              yield call(createOrder, action.payload);
              yield put(clearCartData());
              yield put(getCartItemsStart());
              snack.success("Order created successfully");
            } else {
              console.log("Unauthorized");
            }
          }
          break;

        case getOrdersStart.type:
          {
            const response: any = yield call(getOrders, action.payload);
            yield put(createOrderSuccess({ orders: response.ResponseBody }));
          }
          break;

        case getOrdersBySellerIdStart.type:
          {
            const response: any = yield call(
              getOrdersBySellerId,
              action.payload
            );
            yield put(
              getOrdersBySellerIdSuccess({ orders: response.ResponseBody })
            );
          }
          break;

        case getOrderByOrderIdStart.type:
          {
            const response: any = yield call(getOrderByOrderId, action.payload);
            yield put(getOrderSuccess({ orders: response.ResponseBody }));
          }
          break;

        case updateOrderStatusStart.type:
          {
            const response: any = yield call(updateOrderStatus, action.payload);
            /*yield put(
              getOrdersBySellerIdSuccess({ orders: response.ResponseBody })
            ); */
            snack.success("Order status updated Successfully");
          }
          break;
        default:
          console.error("invalid case");
          break;
      }
    } else {
      console.log("Unauthorized");
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === createOrderStart.type) {
      yield put(createOrderFailure);
    } else if (action.type === getOrdersStart.type) {
      console.log("error in getting order");
    }
  }
}

export default OrderSagaWatcher;
