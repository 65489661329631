//Define type of route
export const USER_ROUTE = "/users";
export const PRODUCT_CATEGORY = "/product-category";
export const PRODUCT = "/product";

const ROUTE_PATHS = {
  EDIT_PRODUCT:"/edit-product/:id",
  HOME: "/",
  BUYER_REGISTER: `${USER_ROUTE}/buyer/register`,
  SELLER_REGISTER: `${USER_ROUTE}/seller/register`,
  SIGN_IN: "/signin",
  DASHBOARD: "/dashboard",
  GUEST_LOGIN: "/signin/guest",
  VERIFY_TOKEN: "/verify-token/:token",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD_OTP: "/reset-password",
  RESET_PASSWORD_EMAIL: "/reset-password/email/:token",
  RESEND_EMAIL: "/resend-email",
  BLOG: "/blog",
  CART: "/cart",
  WISHLIST: "/wishlist",
  CHECKOUT: "/checkout",
  GIFTCARD: "/paysfer-gift-card",
  AFFILIATE_REGISTER: "/affiliate-register",
  AFFILIATE_LOGIN: "/affiliate-login",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about-us",
  TERMS: "/terms",
  AFFILIATE_HOME: "/affiliate-home",
  SPECIAL_OFFER: "/special-offer",
  EXCLUSIVE_COLLECTION: "/exclusive-collection",
  BEST_OFFERS: "/best-offers",
  RATING_REVIEW: "/create-review",

  SELL_ON_RESELL: "/sell-on-resell",
  SELLING_SITEMAP: "/selling-sitemap",
  PRODUCT_PAGE: "/product-page",
  PRODUCT_CATEGORY_ITEM: "/product-page/:id/:title",
  CATEGORIES: "/categories",
  EXPLORE_PRODUCTS: "/explore-products/:category",
  TRACK_ORDER: "/track-order",
  MY_ORDERS: "/my-orders",
  ACCOUNT_SETTINGS: "/account-settings",
  ACCOUNT_SETTING_TYPE: "/account-settings/:setting",
  PAYMENT_PROCESS: "/payment-process/:step",
  EDIT_ADDRESS: "/address/edit",
  PAYPAL_CHECKOUT: "/payment/paypal",
  STRIPE_CHECKOUT: "/payment/stripe",
  PAYMENT_STATUS: "/payment/status/:type",
  STRIPE_PAYMENT_STATUS: "/stripe-payment/status",
  DIRECT_SELLERS_ONBOARDING: "/seller/onboarding/:step",
  SEARCH_RESULTS: "/search-results",
  ADDRESSES: "/addresses",
  CREATE_ADDRESS: "/create-address",
  ADDRESS_EDIT: "/edit-address",
  VERIFY_EMAIL_OTP: "/verify-email-otp",
  PAYMENT_METHODS: "/payment-methods",
  MY_SUBSCRIPTION: "/my-subscriptions",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
  LISTING: "/listing",
  SHIPPING_LABEL: "/dashboard/shipping-label",
  SHOPS: "/shops",
  SHOP: "/shops/:userId",
  HAZARD_PAGE: "/dashboard/shipping-label/hazard-page",
  THANKYOU:"/thankyou",
  
};

export default ROUTE_PATHS;
