import { createSlice } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  error: boolean;
  products: any;
  product: {};
  isSuccess: boolean;
}

const initialState: State = {
  isLoading: true,
  error: false,
  products: [],
  product: {},
  isSuccess:false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    searchDataStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    searchDataSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      products: action.payload.products,
      isSuccess:false,
    }),
    searchDataFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
      isSuccess:false,
    }),
    getProductsStartBasedOnFilters: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    getProduct: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    getProductSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      product: action.payload.product,
      isSuccess:false,
    }),
    getProductFailure: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      product: action.payload.product,
      isSuccess:false,
    }),
    createProductStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      error: false,
      isSuccess:false,
    }),
    createProductSuccess: (state: State, action) => ({
      ...state, 
      product: action.payload.product,
      isLoading: false,
      error: false,
      isSuccess:true,
    }),
    createProductFailure: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: true,
      isSuccess:false,
    }),
    resetProduct: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    getProductBySellerId: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    getProductBySellerIdSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      products: action.payload.products,
      isSuccess:false,
    }),
    getProductBySellerIdFailure: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: true,
      isSuccess:false,
    }),
    editProductStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      error: false,
      isSuccess:false,
    }),
    editProductSuccess: (state: State, action) => ({
      ...state, 
      product: action.payload.product,
      isLoading: false,
      error: false,
      isSuccess:true,
    }),
    editProductFailure: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: true,
      isSuccess:false,
    }),
  },
});

export const {
  searchDataStart,
  searchDataSuccess,
  searchDataFailure,
  getProductsStartBasedOnFilters,
  getProduct,
  getProductSuccess,
  getProductFailure,
  createProductStart,
  createProductSuccess,
  createProductFailure,
  resetProduct,
  getProductBySellerId,
  getProductBySellerIdSuccess,
  getProductBySellerIdFailure,
  editProductStart,
  editProductSuccess,
  editProductFailure
} = productSlice.actions;
export default productSlice.reducer;
