import "../style.css";
import bannerImg from "assets2/images/colorful-pens.jpeg";


const ShopTileLayout = ({
    shop,
    handleClick,
  }: any) => {
    

    // const history = useHistory();
    // const goToShop = (shop: any) => {
    //     history.push({
    //       pathname: `/shops/${shop.shopName}/`
    //     });
    //   };
    console.log('shopshop',shop)
    return(
      <div id="shop-tile-container">
            <div id="shop-tile-banner">
                <img src={bannerImg} alt="ShopBanner"
                  onClick={() => handleClick(shop)} />
            </div>
            <h3 onClick={() => handleClick(shop)}>{shop?.username}</h3>
            
        </div>
    )
  }

export default ShopTileLayout;