import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const createOrder = async (data: any) => {
  let uri = `/orders`;

  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getOrders = async (data: any) => {
  const { status, duration, page } = data;

  let uri = `/orders/history?status=${status}&date=${duration}&page=${page}`;

  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};


export const getOrdersBySellerId = async (data: any) => {
  // sellerId of seller is the UserId 
  const { sellerId} = data;

  let uri = `/orders/seller/${sellerId}`;

  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getOrderByOrderId = async (data: any) => {
  const { orderId} = data;

  let uri = `/orders/${orderId}`;

  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const updateOrderStatus =async (data:any) => {
  const {orderId, sellerId} = data;
  let uri =`/orders/updateStatus/${orderId}&sellerId=${sellerId}`;
  try{
    const res = await axios.post(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
  } catch(e){
    throw e;
  }
}