import axios from "api/axiosController";


export const  getUserShopsByListings = async () => {
  let uri = 'user/shops';
  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
}
