import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "redux/store";
import { getUserShopsByListingsStart } from "redux/userShops";
import ShopList from "./shopList";
import "./style.css";

export default function ShopResults() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const userShop: any = useSelector(
    (state: RootState) => state.userShop.userShops
  );
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [userShopData, setUserShopData] = useState<any>([]);
  const [collegeSelected, setCollegeSelected] = useState<string>("");

  useEffect(() => {
    dispatch(getUserShopsByListingsStart());
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.item.label === "New York University") {
        setCollegeSelected("NYU");
      } else if (location.state.item.label === "Pace University") {
        setCollegeSelected("PACE");
      } else if (location.state.item.label === "Columbia University") {
        setCollegeSelected("COLUMBIA");
      }
      setIsTrue(true);
    } else {
      setIsTrue(false);
    }
  }, [location]);

  useEffect(() => {
    if (isTrue) {
      let data: any = [];
      userShop.forEach((element: any) => {
        if (element.email.match(/edu/)) {
          if (
            collegeSelected === "PACE" &&
            element.collegeName.toUpperCase().match(/PACE/)
          ) {
            data.push(element);
          } else if (
            collegeSelected === "COLUMBIA" &&
            element.collegeName.toUpperCase().match(/COLUMBIA/)
          ) {
            data.push(element);
          } else if (
            collegeSelected === "NYU" &&
            (element.collegeName.toUpperCase().match(/NYU/) || (element.collegeName.toUpperCase().match(/NEW YORK UNIVERSITY/)))
          ) {
            data.push(element);
          }
        }
      });
      setUserShopData(data);
    } else {
      setUserShopData(userShop);
    }
  }, [userShop]);

  return (
    <div id="shops-container">
      <h2>Shops</h2>
      <p>
        Scroll through to see the trending shops on ReSell. Check out student
        shop listings and featured stores!
      </p>
      <div id="shops-display">
        <ShopList data={userShopData} />
      </div>
    </div>
  );
}
