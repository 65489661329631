import * as React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import UserListings from "../../Dashboard/UserListing/userListings";
import bannerImg from "assets2/images/plain-background-Large.jpeg";
// import gearIcon from "assets2/images/gear-icon.png";
import EditShopModal from "./editShopModal";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import { TabContext } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import "./shop.css";

const UserShop = (props: any) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div id="UserShopContainer">
      <Grid item id="full-grid">
        <Grid
          container
          id="vert-container"
          lg={12}
          spacing={2}
          direction="column"
        >
          <Grid item id="banner" lg={12}>
            <img src={bannerImg} alt={bannerImg} />
            {/* uploadedImagesUrls[0].url */}
            <Grid
              item
              id="edit-button"
              lg={2}
              style={{ position: "relative", bottom: "230px", right: "100px" }}
            >
              {props.isUser ? <EditShopModal /> : null}
            </Grid>
            <Grid item id="shop-name" lg={12}>
              <p>{props.user.username}</p>
            </Grid>
          </Grid>
          <Box>
            <TabContext value={value}>
              <Box
                id="Shop-Tabs"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList onChange={handleChange} aria-label="dashboard-tabs">
                  {/* <Tab label="My Page" value="1" /> */}
                  <Tab label="Listings" value="1" className="tab" />
                  <Tab label="About" value="2" className="tab" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <UserListings userId={props.user._id} editIcon={props.editIcon}/>
              </TabPanel>
              <TabPanel value="2">
                <p id="shop-description">{props.user.description}</p>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default UserShop;
