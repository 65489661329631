import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  userShops : any;
}

const initialState: State = {
  isLoading: true,
  error : false,
  userShops : [],
};

export const userShopSlice = createSlice({
  name: 'userShop',
  initialState,
  reducers: {
    getUserShopsByListingsStart: (state: State) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getUserShopsByListingsSuccess: (state: State, action) => {
      return ({
      ...state,
      error: false,
      isLoading: false,
      userShops: action.payload.userShops,
    })},
    getUserShopsByListingsFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),

  }
});

export const {
  getUserShopsByListingsStart,
  getUserShopsByListingsSuccess,
  getUserShopsByListingsFailure,
} = userShopSlice.actions;
export default userShopSlice.reducer;
