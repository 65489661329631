import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getProductBySellerId } from "redux/product";
import SingleProductLayout from "../../ExploreProductsPage/searchResults/SingleProductLayout";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { titelize } from "utils/titelize";

const useStyles = makeStyles((theme) => ({
  item: {
    boxShadow: "none",
    transition: "1s",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
      transition: "1s",
    },
  },
}));
const UserListings = (props:any) => {
  const dispatch = useDispatch();
  const data: any = useSelector((state: RootState) => state.product.products);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const user = {
      sellerId: props.userId,
      page: 1,
    };
    dispatch(getProductBySellerId(user));
  }, [props.userId]);

  const handleClick = (item: any) => {
    history.push({
      pathname: `/product-page/${item._id}/${titelize(item.title)}`,
      state: {
        item: item,
        src: "from-search",
      },
    });
  };
  const handleEditIcon = (item:any) => {
    history.push({
      pathname:`/edit-product/${item._id}`
    })
  }
  return (
    <Box sx={{ maxWidth: "1600px", margin: "0 auto" }}>
      <Grid container spacing={2} justifyContent="flex-start">
        {data?.map((product: any) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.item}
            sx={{ transition: "box-shadow" }}
          >
            {" "}
            <SingleProductLayout 
              product={product} 
              handleClick={handleClick} 
              editIcon = {props.editIcon} 
              handleEditIcon ={handleEditIcon}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default UserListings;
