export const AB_1 = "https://storage.googleapis.com/resell/Assets2/ab-1.webp"
export const TOOLS = "https://storage.googleapis.com/resell/Assets2/tools.webp"
export const ANIMALS_AND_PET_SUPPLIES = "https://storage.googleapis.com/resell/Assets2/Animals_and_Pet_Supplies.webp"
export const ANTI_PERSPIRANT = "https://storage.googleapis.com/resell/Assets2/Anti_Perspirant.webp"
export const APPAREL_AND_ACCESSORIES = "https://storage.googleapis.com/resell/Assets2/Apparel_and_Accessories.webp"
export const ART_AND_ENTERTAINMENT = "https://storage.googleapis.com/resell/Assets2/Art_and_Entertainment.webp"
export const BABY_AND_TODDLER = "https://storage.googleapis.com/resell/Assets2/Baby_and_toddler.webp"
export const BUSSINESS_AND_INDUSTRIAL = "https://storage.googleapis.com/resell/Assets2/Bussiness_and_Industrial.webp"
export const CEREMONIAL = "https://storage.googleapis.com/resell/Assets2/Ceremonial.webp"
export const EBOOK_READER_CASES = "https://storage.googleapis.com/resell/Assets2/Ebook_Reader_Cases.webp"
export const FESTIVAL_1 = "https://storage.googleapis.com/resell/Assets2/Festival-1.webp"
export const FESTIVAL_2 = "https://storage.googleapis.com/resell/Assets2/Festival-2.webp"
export const FESTIVAL_3 = "https://storage.googleapis.com/resell/Assets2/Festival-3.webp"
export const FESTIVAL_4 = "https://storage.googleapis.com/resell/Assets2/Festival-4.webp"
export const FOOD_BEVERAGES = "https://storage.googleapis.com/resell/Assets2/Food_Beverages.webp"
export const HARDWARE = "https://storage.googleapis.com/resell/Assets2/Hardware.webp"
export const HEALTH_AND_BEAUTY = "https://storage.googleapis.com/resell/Assets2/Health_and_Beauty.webp"
export const LUGGAGE_AND_BAGS = "https://storage.googleapis.com/resell/Assets2/Luggage_and_Bags.webp"
export const MATURE = "https://storage.googleapis.com/resell/Assets2/Mature.webp"
export const MOBILE_PREPAID_CARDS = "https://storage.googleapis.com/resell/Assets2/Mobile_Prepaid_Cards.webp"
export const RESELL_MARKETPLACE_LOGO = "https://storage.googleapis.com/resell/Assets2/Resell-Marketplace-Logo.webp"
export const SOFTWARE = "https://storage.googleapis.com/resell/Assets2/Software.webp"
export const SPORTING_GOODS = "https://storage.googleapis.com/resell/Assets2/Sporting_Goods.webp"
export const TIKTOKICON = "https://storage.googleapis.com/resell/Assets2/TiktokIcon.webp"
export const VEHICLES_AND_PARTS = "https://storage.googleapis.com/resell/Assets2/Vehicles_and_Parts.webp"
export const ADVERTISEMENT = "https://storage.googleapis.com/resell/Assets2/advertisement.webp"
export const BG = "https://storage.googleapis.com/resell/Assets2/bg.webp"
export const CAP = "https://storage.googleapis.com/resell/Assets2/cap.webp"
export const DASHBOARD_INSTRUCTIONS = "https://storage.googleapis.com/resell/Assets2/dashboard_instructions.webp"
export const DELIVERY = "https://storage.googleapis.com/resell/Assets2/delivery.webp"
export const DEVICE = "https://storage.googleapis.com/resell/Assets2/device.webp"
export const ELECTRONICS = "https://storage.googleapis.com/resell/Assets2/electronics.webp"
export const EREADER = "https://storage.googleapis.com/resell/Assets2/ereader.webp"
export const FLAG = "https://storage.googleapis.com/resell/Assets2/flag.webp"
export const FURNITURES = "https://storage.googleapis.com/resell/Assets2/furnitures.webp"
export const GAMING = "https://storage.googleapis.com/resell/Assets2/gaming.webp"
export const GIFT_1 = "https://storage.googleapis.com/resell/Assets2/gift-1.webp"
export const GIFT = "https://storage.googleapis.com/resell/Assets2/gift-1.webp"
export const GIFT_2 = "https://storage.googleapis.com/resell/Assets2/gift-2.webp"
export const GIFT_3 = "https://storage.googleapis.com/resell/Assets2/gift-3.webp"
export const GIFT_4 = "https://storage.googleapis.com/resell/Assets2/gift-4.webp"
export const GREEN_HOUSE = "https://storage.googleapis.com/resell/Assets2/green-house.webp"
export const HBD = "https://storage.googleapis.com/resell/Assets2/hbd.webp"
export const ITEAM_4 = "https://storage.googleapis.com/resell/Assets2/iteam-4.webp"
export const JEWELERY = "https://storage.googleapis.com/resell/Assets2/jewelery.webp"
export const KITCHEN = "https://storage.googleapis.com/resell/Assets2/kitchen.webp"
export const OFFICE_SUPPLIES = "https://storage.googleapis.com/resell/Assets2/office_supplies.webp"
export const PRODUCT_ADVERTISMENT = "https://storage.googleapis.com/resell/Assets2/product-advertisment.webp"
export const PRODUCT_TRACKING_IMG = "https://storage.googleapis.com/resell/Assets2/product-tracking-img.webp"
export const PROFILEPIC_DEFAULT = "https://storage.googleapis.com/resell/Assets2/profilepic_default.webp"
export const SUNGLASSES = "https://storage.googleapis.com/resell/Assets2/sunglasses.webp"
export const TOYS = "https://storage.googleapis.com/resell/Assets2/toys.webp"
export const USER_1 = "https://storage.googleapis.com/resell/Assets2/user-1.webp"
export const VIDEO_CONSOLE = "https://storage.googleapis.com/resell/Assets2/video-console.webp"
export const BAGS = "https://storage.googleapis.com/resell/Assets2/Luggage_and_Bags.png"