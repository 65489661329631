import React, { useEffect, useRef, useState, SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import snack from "components/wrapper/snack";
import gearIcon from "assets2/images/gear-icon.png";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocumentsStart } from "redux/upload";
import "./shop.css";
import { accountSettingsUpdateStart } from "redux/accountSettings";
import { useHistory } from 'react-router-dom';

export default function EditShopModal() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // add a page refresh to handle handle close
  const handleClose = () => {
    setOpen(false);
    // redirect to dashboard 
    history.push('/dashboard');
  };
  const userData: any = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  // const accessToken = useSelector((state: RootState) => state.user.accessToken);

  // const inputRef = useRef<HTMLInputElement>(undefined!);
  const [uploadedImages, setUploadedImages] = useState<FileList | null>(null);
  const uploadedImagesUrls: any = useSelector(
    (state: RootState) => state.upload.documents
  );
  // define const submitForm --> check out ListingPageForm.tsx
  // this will update the user's schema on submitting

  const onUploadAttachments = (e: SyntheticEvent) => {
    e.preventDefault();

    if (
      !userData.user.firstName ||
      !userData.user.lastName ||
      !userData.user.email
    ) {
      snack.error("Please Login");
    } else {
      if (uploadedImages === null) {
        snack.error("Please select images to upload");
      } else {
        dispatch(
          uploadDocumentsStart({
            documents: uploadedImages,
            firstName: userData.user.firstName,
            lastName: userData.user.lastName,
            email: userData.user.email,
          })
        );
        dispatch(
          accountSettingsUpdateStart({
            bannerImageUrl : {bannerImages : uploadedImagesUrls.map((item:any) => item.url)},
          })
        );
        // pass this val into the AccountSettings update API 
      }
    }
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(50% + 100px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div id="Modal">
      <Button onClick={handleOpen}>
        <p>Edit</p>
        <img src={gearIcon} alt="" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="modal-content">
            <h3 id="modal-modal-title">Edit Banner</h3>
            <div id="shop-edit-options">
              <div
                id="imagePlaceholder"
                data-et-name="profileEditorImageSection"
              >
                <div>
                  <label className="d--b">
                    <div className="">
                      <input
                        id="img-file-input"
                        name="img-file-input"
                        accept=".mov, .mp4, .png, .jpg, .jpeg"
                        aria-label="img-file-input"
                        type="file"
                        multiple
                        className="fontSizeFile"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={(e) => {
                          e.preventDefault();
                          setUploadedImages(e.target.files);
                        }}
                      />
                    </div>
                  </label>
                </div>
                <button className="upload-button" onClick={onUploadAttachments}>
                  UPLOAD
                </button>
              </div>
              {uploadedImagesUrls.map((item: any) => (
                // banner src == uploadedImagesUrls[0].url
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "35px",
                    marginTop: "12px",
                  }}
                >
                  <img
                    alt="img"
                    style={{
                      maxWidth: "200px",
                    }}
                    src={item.url}
                  />
                </div>
              ))}
            </div>
            <p id="close-modal-text">Click anywhere outside window to close.</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
