import { combineReducers } from '@reduxjs/toolkit';
import cartActions from 'redux/cart';
import categoriesActions from 'redux/categories';
import paymentActions from 'redux/payment';
import userActions from 'redux/user';
import userShopsActions from 'redux/userShops';
import wishlistActions from 'redux/wishlist';
import addressActions from 'redux/address';
import accountSettingActions from 'redux/accountSettings';
import uploadActions from 'redux/upload';
import orderActions from 'redux/order';
import productActions from 'redux/product';
import kitchenActions from 'redux/Home/kitchen';
import gamesActions from 'redux/Home/games';
import electronicsActions from 'redux/Home/electronics';
import bagsActions from 'redux/Home/bags';
import valentineActions from 'redux/Home/valentine';
import mobileActions from 'redux/Home/mobile';
import travelActions from 'redux/Home/travel';
import shoesActions from 'redux/Home/shoes';
import stationaryActions from 'redux/Home/stationary';
import reviewActions from 'redux/rating';
import subscribeActions from 'redux/newsLetter';
import subscriptionActions from 'redux/subscription';

const rootReducer = combineReducers({
  cart: cartActions,
  categories: categoriesActions,
  payment: paymentActions,
  user: userActions,
  userShop: userShopsActions,
  wishlist: wishlistActions,
  address: addressActions,
  account: accountSettingActions,
  upload: uploadActions,
  orders: orderActions,
  product: productActions,
  kitchen: kitchenActions,
  games: gamesActions,
  electronics: electronicsActions,
  bags: bagsActions,
  valentine: valentineActions,
  mobile: mobileActions,
  travel: travelActions,
  shoes: shoesActions,
  stationary: stationaryActions,
  rating: reviewActions,
  subscribe: subscribeActions,
  subscription: subscriptionActions
});

export default rootReducer;
