import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserListings from "../../Dashboard/UserListing/userListings";
import "../style.css";
import UserShop from "./userShop";

const ShopListProduct = () => {
  const location: any = useLocation();
  const [id, setId] = useState(null);
  useEffect(() => {
    setId(location.state.item._id);
    console.log("locationlocation", location.state.item._id);
  }, [location]);

  return <> {id !== null ?
    <UserShop  user={location.state.item}/> : null}</>;
};

export default ShopListProduct;
