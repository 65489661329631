import jwtDecode from 'jwt-decode';
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { getSecretKeyFailure, getSecretKeyStart, getSecretKeySuccess, updateSecretKeyStart, updateSecretKeySuccess, updatePaymentMethodsStart, updatePaymentMethodsSuccess, getCheckoutUrlStart, getCheckoutUrlSuccess, getPaymentStatusStart, getPaymentStatusSuccess, getPaymentStatusFailure } from 'redux/payment';
import { addPaymentMethod, deletePaymentMethod, getPaymentMethods, getSecretKeyData, getStripeCheckoutUrl, updateStripePaymentIntent, getPaymentStatus } from 'redux/services/payment';
import { getAccessToken } from 'utils/auth';

function* paymentSagaWatcher() {
  yield takeLatest(
    [getSecretKeyStart.type, updateSecretKeyStart.type, updatePaymentMethodsStart.type, getCheckoutUrlStart.type],
    paymentSagaWorker,
  );
}

function* paymentSagaWorker(action: any): any {
  try {
    switch (action.type) {
      case getSecretKeyStart.type: {

        const data = yield call(getSecretKeyData, action.payload);
        yield put(getSecretKeySuccess({ secretKey: data.ResponseBody.clientSecret, paymentMethods: data.ResponseBody.paymentMethods }));
        break;
      }

      case getCheckoutUrlStart.type: {
        const data = yield call(getStripeCheckoutUrl, action.payload);
        yield put(getCheckoutUrlSuccess(data.ResponseBody));
        break;
      }

      case updateSecretKeyStart.type: {
        const data = yield call(updateStripePaymentIntent, action.payload);
        yield put(updateSecretKeySuccess({ secretKey: data.ResponseBody.clientSecret }));
        break;
      }

      case updatePaymentMethodsStart.type: {
        const user: any = jwtDecode(JSON.stringify(getAccessToken()));
        let userId = user._id;
        if (action.payload.operation === "delete") {
          yield call(deletePaymentMethod, action.payload.paymentMethodId);
        } else if (action.payload.operation === "add") {
          action.payload["userId"] = userId;
          yield call(addPaymentMethod, action.payload);
        }
        const data = yield call(getPaymentMethods);
        yield put(updatePaymentMethodsSuccess({ paymentMethods: data.ResponseBody.paymentMethods }));
        break;
      }
      case getPaymentStatusStart.type: {
        const data = yield call(getPaymentStatus, action.payload);
        break;
      }
      default:
        break;
    }
  } catch (err) {
    console.log(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getSecretKeyStart.type || action.type === updateSecretKeyStart.type || action.type === updatePaymentMethodsStart.type) {
      yield put(getSecretKeyFailure(err));
    }
  }
}

export default paymentSagaWatcher;
