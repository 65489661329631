import * as React from 'react';
import { Link } from '@mui/material';

// external application icons
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import '../../../styles/common.css';
import '../../../styles/responsive-common.css';
import { useHistory } from "react-router-dom";
import { TIKTOKICON } from 'assets2/images';

const Footer: React.FC = () => {
  const history = useHistory();
 

  return (
    <footer>
      <div className="container-fluid">
        <div className="find-out-section">
          <div className="d-flex w-100 justify-content-between">
            <div className="alphabetically-wrap">
              <div className="footer-links">
                {/* links */}
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/about-us" });
                  }}
                  
                >
                  About
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/sell-on-resell" });
                  }}
                >
                  Sell on Resell
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/contact-us" });
                  }}
                >
                  Support
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/blog" });
                  }}
                >
                  Blog
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/privacy-policy" });
                  }}
                >
                  Terms
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/privacy-policy" });
                  }}
                >
                  Privacy
                </Link>
              </div>

              <div className="social-media-container">
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.instagram.com/resellmarketplacenyc/"
                  aria-label="Instagram Icon"
                >
                  <InstagramIcon />
                </a>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.facebook.com/people/ReSell-NY/100083370759187/"
                  aria-label="Facebook Icon"
                >
                  <FacebookIcon />
                </a>
                <a
                  className="social-media"
                  target="blank"
                  href="https://twitter.com/resell_marketpl"
                  aria-label="Twitter Icon"
                >
                  <TwitterIcon />
                </a>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.tiktok.com/@resellmarketplace"
                  aria-label="Tiktok Icon"
                >
                  <img src={TIKTOKICON} alt="no-img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
