//import '../../../styles/common.css';
//import '../../../styles/responsive-common.css';

import React from 'react';
import { BuyableProduct } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useState } from "react";
import LazyLoadWrapper from "components/wrapper/lazy-load-wrapper";
const ListingPageForm =  React.lazy(() => import("./ListingPageForm")) ;

export default function Listing() {
  const [product, setProduct] = useState<BuyableProduct>({
    title: "",
    description: "",
    color:"",
    size:"",
    category: "",
    brand: "",
    condition: "",
    saleprice: 0,
    shippingcoststandard: 0,
    shippingcostexpedited: 0,
    shippinglength: "",
    shippingwidth: "",
    shippingheight: "",
    shippingweight: "",
    productgroupimageurl: [],
    videourl: [],
    material: "",
    lengthunit: "",
    widthunit: "",
    price: 0,
    Quantity: 0,
    ListingStatus: "",
    userId: "",
    userName:"",
    meta: [],
    collegeName:"",
  });
  
  return (
    <div className="col-md-12 col-lg-12">
      <LazyLoadWrapper>
        <ListingPageForm product={product} setProduct={setProduct} />
      </LazyLoadWrapper>
    </div>
  );
}
